import React from "react";

const AboutCompany = () => {
  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-25">
                    About our Company
                  </span>
                  <h2 className="tp-pt-size mb-40">
                    A Brief Detail About Our Company or Business
                  </h2>
                </div>
                <div className="about-tb-content">
                  <nav>
                    <div className="nav mb-30" id="nav-tab" role="tablist">
                      <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        About Company
                      </button>

                      {/* <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                        About Business
                      </button> */}
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p>
                        <strong>JB Placement Services</strong> company serving to client with best practices. JB has been immensely successful in creating a network of highly adept intelligent workforce that can help a company achieve their mission-critical projects and goals. Keeping pace with projects and being on the lookout for the extremely talented individuals has become ever more challenging. Here is where JB Placement comes into play to bring the best suited talent for your company.                      </p>

                      <p>
                        All of our resources are multifaceted and come with years of commended performance. Project completion rate of our consultants is 97%, 3% is attributed to unforeseen situations like project shutdown, health issues, relocation, etc. We appreciate the experience that our candidates bring from various industries and we like to leverage it for the mutual benefit of both clients and candidates. We specialize in FMCG, FMCD, Real Estate, Sanitary, EPC,information technology, Banking Sector, NBFC/BFSI, Automobile, Manufacturing and Startups.
                        <br /><strong>We Work On quality Not Quantity</strong>
                      </p>
                    </div>
                    {/* <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    >
                      <p>
                        Lorem ipsum dolor sit amet, qui case probo velit no, an postea scaevola partiendo mei. Id mea fuisset perpetua referrentur. Ut everti ceteros mei, alii discere eum no, duo id malis iuvaret. Ut everti ceteros mei, alii discere eum no, duo id malis iuvaret.
                      </p>

                      <p>
                        Lorem ipsum dolor sit amet, qui case probo velit no, an postea scaevola partiendo mei. Id mea fuisset perpetua referrentur. Ut everti ceteros mei, alii discere eum no, duo id malis iuvaret. Ut everti ceteros mei, alii discere eum no, duo id malis iuvaret.
                      </p>
                    </div> */}
                    {/* <div
                      className="tab-pane fade"
                      id="nav-value"
                      role="tabpanel"
                      aria-labelledby="nav-value-tab"
                    >
                      <p>
                        Neque volutpat ac tincidunt vitae semper quis lectus
                        nulla at. Nibh nisl id venenatis a condimentum. Libero
                        justo laoreet sit amet. Nisl nisi scelerisque eu
                        ultrices vitae. Dolor sit amet consectetur adipiscing
                        elit pellentesque. Phasellus egestas tellus rutrum
                      </p>

                      <p>
                        ullamcorper morbi tincidunt ornare massa potenti nullam
                        ac tortor vitae purus. faucibus ornare suspendisse.
                        Donec enim diam vulputate ut pharetra sit amet aliquam
                        id. Eu mi bibendum neque egestas congue purus non enim
                        praesent.Donec enim diam vulputate ut pharetra sit amet
                        aliquam id. Eu mi bibendum neque
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                <div className="about-page-img shadow">
                  <img src="assets/img/about/about-page.jpeg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row text-center">
            <h3>VISSION</h3>
            <p>
              <ul>
                <li style={{ background: "#e92f39" }}>
                  <strong style={{ color: "#fff" }}>We Work On Quality Not Quantity</strong>
                </li>
              </ul>
            </p>
          </div>
          <br />
          <div className="row text-center">
            <h3>MISSION</h3>
            <p>
              <ul>
                <li>
                  <strong>Integrity:</strong><br />
                  We embrace and uphold the highest standards of personal and professional ethics, honesty and trust.
                </li>
                <li>
                  <strong>Respect:</strong><br />
                  We treat everyone with uncompromising respect, civility and fairness.
                </li>
                <li>
                  <strong>Collaboration:</strong><br />
                  We work as a team and share knowledge for continuous improvement, learning and innovation.
                </li>
                <li>
                  <strong>Empowerment:</strong><br />
                  We are empowered to deliver operational excellence through innovation and leadership at all levels.
                </li>
                <li>
                  <strong>Responsibility:</strong><br />
                  We are responsible to fulfill our commitments to colleagues and clients with a clear understanding of the urgency and accountability inherent in those commitments.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
