import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <div className="tp-footer__area custome-blue-bg">
        <div className="tp-footer">
          {/* <!-- main-footer start  --> */}
          <div className="tp-footer__main">
            <div className="container">
              <div className="tp-footer-border pt-100 pb-70">
                <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2  pb-30">
                      <h3 className="tp-footer__widget-title">About Us</h3>
                      <Link to="/" href="#"><img src="/assets/img/logo/logo.png" width={80} alt="" /></Link><br/><br/>
                      <p>JB PLACEMENT SEVICES is a organization with experience in Placement, Manpower Recruitment, Mass Hiring and developing Staffing Solutions</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 pb-30">
                      <h3 className="tp-footer__widget-title">Our Service</h3>
                      <ul>
                        <li><a href="#">- Permanent Staffing</a></li>
                        <li><a href="#">- Temporary & Contractual Staffing</a></li>
                        <li><a href="#">- Recruitment Processing Outsourcing</a></li>
                        <li><a href="#">- Campus Recruitment</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="tp-footer__widget tp-footer__2  pb-30">
                      <h3 className="tp-footer__widget-title">Quick Links</h3>
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/service">Service</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 pb-30">
                      <h3 className="tp-footer__widget-title">Our Location</h3>
                      <ul>
                        <li><a href="#">Ganesh Apartment 1st Floor, 9-Dukan kalwar Road, Jhotwara, Jaipur (Raj)</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- main footer end  --> */}
        </div>
      </div>

      {/* <!-- footer copy right --> */}
      <div className="top-footer-copyright pt-30 pb-30 custome-blue-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-copyrigh-text text-center text-white">
                <span>© {new Date().getFullYear()} JB Placement Services - All Rights Reserved.</span><br />
                <span>Privacy Policy</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer copyright end  --> */}
    </footer>
  );
};

export default Footer;