import React from "react";
import { Link } from "react-router-dom";

const Menus = () => {
  return (
    <ul>
      <li>
        <Link to="/"> <strong>Home</strong></Link>
      </li>
      <li>
        <Link to="/about"><strong>About us</strong></Link>
      </li>
      <li>
        <Link to="/service"><strong>Services</strong></Link>
      </li>
      <li>
        <Link to="/contact"><strong>contact</strong></Link>
      </li>
    </ul>
  );
};

export default Menus;
