import React, { useState } from "react";
import { Link } from "react-router-dom";
// internal
import menu_data from "./MobileData";

const MobileMenus = () => {
  const [navTitle, setNavTitle] = useState("");
  //openMobileMenu
  const openMobileMenu = (menu) => {
    if (navTitle === menu) {
      setNavTitle("");
    } else {
      setNavTitle(menu);
    }
  };
  return (
    <nav className="mean-nav">
      <ul>
        {menu_data.map((menu, i) => (
          <React.Fragment key={i}>
            {
            <li>
              <Link to={menu.link}>{menu.title}</Link>
            </li>
            }
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default MobileMenus;
