import React from "react";
import Brands from "../common/Brands/Brands";
import Footer from "../common/Footer/Footer";
import Menu from "../common/Menu/Menu";
import Team from "../common/Team/Team";
import Testimonial from "../common/Testimonial/Testimonial";
import OurService from "../Pages/Service/OurService";
import About from "./About";
import SliderHomeThree from "./SliderHomeThree";
import Category from "./Category";
import Contact from "./Contact";

const Home = () => {
  return (
    <>
      <Menu header_style_2={true} />
      {/* <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block pt-70"></div> */}
      <SliderHomeThree />
      <About />
      <br/>
      <br/><br/>
      <OurService />
      <Category />
      {/* <Team /> */}
      <Testimonial />
      <Contact />
      {/* <Brands /> */}
      <Footer />
    </>
  );
};

export default Home;
