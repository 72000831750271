import React from "react";
import { Link } from "react-router-dom";

// single service
export function ServiceItem({ icon, title, desc }) {
  return (
    <div className="col-xl-3 col-md-6">
      <div className="tp-service text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30">
        <div className="tp-service__icon">
          <i className={icon}></i>
        </div>
        <h3 className="tp-service__title pt-40 pb-25">
          <Link to="/service-details">{title}</Link>
        </h3>
        <p>{desc}</p>
        <Link className="tp-btn-sm-black" to="#">
          <i className="fa fa-angle-double-right"></i> View MOre
        </Link>
      </div>
    </div>
  );
}

export default ServiceItem;
