import React from "react";
import { ServiceItem } from "../Service/Services";

const OurService = () => {
  return (
    <>
      <div className="tp-service-ara grey-bg-4 pt-70 pb-110">
        <div className="container">
          <div className="row justify-content-center">
            <h3 className="text-center">Our Services</h3>
          </div>
          <br />
          <div className="row">
            <ServiceItem
              icon="fas fa-bullhorn"
              title="Permanent Staffing"
              desc="Permanent staffing involves providing long-term employment candidates based on desired candidate requirements. Permanent staffing offers the best-fit candidates from a talent pool of active and passive candidates."
            />
            <ServiceItem
              icon="fas fa-chart-area"
              title="Temporary & Contractual Staffing"
              desc="Succinctly, temp staff is workers for an especial duration or on the project-basis, instead of recruiting them permanently. The Temp is the temporary worker who works with the client firm, however, is on the payroll of the 3rd party staffing firm."
            />
            <ServiceItem
              icon="fas fa-brush"
              title="Recruitment Processing Outsourcing"
              desc="RPO stands for recruitment process outsourcing. It is a business model whereby a company outsources the management of the recruitment function (in whole or part) to a third-party specialist to drive cost, quality, efficiency."
            />
            <ServiceItem
              icon="fas fa-draw-polygon"
              title="Campus Recruitment"
              desc="Campus placement or campus recruiting is a program conducted within universities or other educational institutions to provide jobs to students nearing completion of their studies. In this type of program, the educational institutions partner with corporations who wish to recruit from the student population."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;
