import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="pt-120">
        <div className="container ">
          <div className="section-content">
            <div className="row">
              <div className="col-md-8 col-sm-6">
                <form className="contact-form cons-contact-form">
                  <div className="section-head mt-5 mb-4">
                    <div className="mt-separator-outer separator-left">
                      <div className="mt-separator">
                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-golden">Get</span> In touch</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label className="control-label tp-contact-label" for="UserName">Name</label>
                      <input className="form-control" placeholder="Enter your Name" id="username" type="text" name="UserName" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label className="control-label tp-contact-label" for="Email">Email</label>
                      <input className="form-control" placeholder="Enter your Email" id="email" type="email" name="Email" />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="control-label tp-contact-label" for="PhoneNumber">Phone Number</label>
                      <input className="form-control" placeholder="Enter your Phone Number" id="phonenumber" type="text" name="PhoneNumber" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label tp-contact-label" for="UserMessage">Message</label>
                    <textarea type="text" rows="5" className="form-control" placeholder="Enter your Message" id="msg" name="UserMessage"></textarea>

                    <span className="text-danger field-validation-valid" data-valmsg-for="UserMessage" data-valmsg-replace="true"></span>
                  </div>
                  <br />
                  <button className="btn btn-primary btn-golden text-black" id="btnSubmit">Send Message</button>
                </form>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="contact-info m-b30">
                  <div className="section-head mt-5 mb-4">
                    <div className="mt-separator-outer separator-left">
                      <div className="mt-separator">
                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-golden">Contact</span> Info</h2>
                      </div>
                    </div>
                  </div>

                  <div className="card bg-dark p-a20 text-white">
                    <div className="card-body">
                      <div className="row d-none">
                        <div className="col-md-12">
                          <div className="mt-icon-box-wraper left p-b-20">
                            <div className="icon-content">
                              <h5 className="to-contact-detail-h5"><i className="fa fa-phone mr-2 text-golden"></i>Phone number</h5>
                              <p className="text-white">+91-XXXXXXXXXX</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mt-icon-box-wraper left p-b-20">
                            <div className="icon-content">
                              <h5 className="to-contact-detail-h5"><i className="fa fa-envelope mr-2 text-golden"></i>Email address</h5>
                              <p className="text-white">info@jbplacement.in</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mt-icon-box-wraper left">
                            <div className="icon-content">
                              <h5 className="to-contact-detail-h5"><i className="fa fa-map-marker mr-2 text-golden"></i>Address info</h5>
                              <p className="text-white">Ganesh Apartment 1st Floor, 9-Dukan kalwar Road, Jhotwara, Jaipur (Raj)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div className="contact-from-section pt-140">
        <div className="tp-ct-map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227598.65101921256!2d75.53090884053985!3d26.959648998185635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4d06ef5ffac3%3A0x98f901083fc1b10d!2sJB%20Placement%20Services!5e0!3m2!1sen!2sin!4v1678281911192!5m2!1sen!2sin"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
