import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      {/* <!-- tp-about-3 start  --> */}
      <br/>
      <br/>
      <br/>
      <div className="tp-ab-section">
        <div className="container">
          <div className="row align-items-center tp-flex-reverse">
            <div className="col-lg-6">
              <div className="tp-ab-info pr-40">
                <div className="tp-ab-subtitle mb-10">
                  {/* <span className="text-primary">Professional Services By <b>JB Placement Services</b></span> */}
                </div>
                <h3 className="tp-ab-title mb-35 text-primary">WHO WE ARE ?</h3>
                <p>
                <strong>JB PLACEMENT SEVICES</strong> is a organization with experience in Placement, Manpower Recruitment, Mass Hiring and developing Staffing Solutions.

We firmly believes in progress and the pursuit of excellence in our work and our main objective is to deliver the best services in terms of recruitment of quality manpower to our clients.
                </p>
                <div>
                  <Link to="/service" className="tp-btn">
                    All Service <i class="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-ab-img text-md-end w-img">
                <img src="assets/img/about/about-i.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- tp-about-3 end  --> */}
    </>
  );
};

export default About;
