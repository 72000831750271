import React from 'react';
import { Route, Routes } from "react-router-dom";
import BackToTop from '../components/BackToTop';
import ContextProvider from '../components/context/ContextProvider';
import Home from '../components/Home/Home';
import About from '../components/Pages/About/About';
import ContactUs from '../components/Pages/ContactUs/ContactUs';
import Service from '../components/Pages/Service/Service';
import AnimateMouse from '../components/common/AnimateMouse';


const AppNavigation = () => {
  return (
    <ContextProvider>
      <AnimateMouse/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
      <BackToTop/>
    </ContextProvider>
  );
};

export default AppNavigation;